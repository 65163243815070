import "./App.css";
import {useEffect, useState} from "react";
import {premiums} from "./constants/premiums";
import {paymentMethods} from "./constants/paymentMethods";
import {getApiUrl, validateAccount} from "./helpers";
import {
    AccountValidationState,
    mapValidationStateToCSS,
    mapValidationStateToTitle
} from "./constants/accountValidationStates";
import {PERSIST_USER_ID_KEY, USER_ID_LENGTH} from "./constants/commonConstants";
import ClipLoader from "react-spinners/ClipLoader";
import {Button} from "./components/Button";
import {useSearchParams} from "react-router-dom";

function App() {
    const [userId, setUserId] = useState('');
    const [searchParams, _] = useSearchParams();
    const [activePremium, setActivePremium] = useState('month');
    const [method, setMethod] = useState(paymentMethods[0].identifier);
    const [emptyUserIdError, setEmptyUserIdError] = useState(false);
    const [validationState, setAccountValidationState] = useValidationState(userId);
    const [isFetching, setIsFetching] = useState(false);
    const appVersion = searchParams.get('ver');

    const validateAccountAndUpdateState = (userId) => {
        validateAccount(userId)
            .then((res) => {
                if (res) {
                    setAccountValidationState(AccountValidationState.CORRECT_ID);
                } else {
                    setAccountValidationState(AccountValidationState.WRONG_ID);
                }
            })
            .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
    }
    useEffect(() => {
        const searchParamsUserId = searchParams.get('userId');
        const localStorageUserId = localStorage.getItem(PERSIST_USER_ID_KEY);
        const persistedUserId = searchParamsUserId || localStorageUserId;
        if (persistedUserId) {
            // Restore persisted uid on mount
            setUserId(persistedUserId);
            validateAccountAndUpdateState(persistedUserId);
        }
        const paramPremium = searchParams.get('premium');
        if (paramPremium && premiums.find((p) => p.identifier === paramPremium)) {
            setActivePremium(paramPremium);
        }
    }, []);

    async function onFormSubmit(event) {
        event.preventDefault();

        if (AccountValidationState.CORRECT_ID !== validationState) {
            return;
        }

        if (userId.length === 0) {
            setEmptyUserIdError(true);
            return;
        }
        // If all checks were passed then persist uid
        localStorage.setItem(PERSIST_USER_ID_KEY, userId);
        setIsFetching(true);
        const premium = premiums.find((p) => p.identifier === activePremium);
        const urlParams = new URLSearchParams({
            userId,
            method,
            appVersion,
            premium: premium.identifier,
        });
        fetch(getApiUrl() + `?${urlParams.toString()}`)
            .then(async (response) => {
                if (response.status === 200) {
                    const {url} = await response.json();
                    window.location.href = url;
                } else {
                    alert(`При попытке создать ссылку на оплату что-то сломалось: ${response.statusText} (${await response.text()})`);
                }
            })
            .catch(() => {
                alert('Что-то пошло не так');
            })
            .finally(() => setIsFetching(false));
    }

    return (
        <div className={'App-container'}>
            <form onSubmit={onFormSubmit}>
                <h1 className={'App-header'}>
                    Премиум подписка в приложении <span>anon.chat</span> на iOS и Андроид
                </h1>
                <Section>
                    <Title>
                        Введите ID аккаунта приложения
                    </Title>
                    <Subtitle>
                        Чтобы получить ID своего аккаунта: откройте настройки приложения → О приложении → Системная
                        информация → ID аккаунта или <a href={"anonchat://settings/about/system-info?buyPremium=true"}>нажмите
                        сюда</a>
                    </Subtitle>
                    <div className={'App-input-user-id-container'}>
                        <input
                            className={
                                `App-input-user-id
                                ${emptyUserIdError && AccountValidationState.CORRECT_ID !== validationState && 'invalid'} 
                                ${AccountValidationState.NONE !== validationState && 'extra-padding'}`
                            }
                            type="text"
                            name="userid"
                            placeholder={'b62a7e9f-cb71-41a8-9a7c-30e01fb37cb6'}
                            value={userId}
                            onChange={(e) => {
                                setAccountValidationState(AccountValidationState.NONE);
                                setUserId(e.target.value?.trim());
                                // If user removes input's value then remove persisted uid
                                if (e.target.value?.trim() === "") {
                                    localStorage.removeItem(PERSIST_USER_ID_KEY);
                                }
                            }}
                            onFocus={(event) => {
                                // Wrapping select() in setTimeout to make it work in Safari
                                // https://stackoverflow.com/questions/54229359/why-does-select-not-work-on-safari-with-reactjs
                                setTimeout(() => event.target.select(), 0);
                            }}
                            onBlur={() => {
                                if (validationState !== AccountValidationState.NONE) {
                                    // If validation state already exists, no need to validate again
                                    return;
                                }

                                if (userId.length !== 0) {
                                    setAccountValidationState(AccountValidationState.VALIDATING);
                                    validateAccountAndUpdateState(userId);
                                } else {
                                    setAccountValidationState(AccountValidationState.NONE);
                                }
                            }}
                        />
                        {validationState !== AccountValidationState.NONE &&
                            <span
                                className={`App-account-validating-state ${mapValidationStateToCSS[validationState]}`}>
                            {validationState === AccountValidationState.VALIDATING && <ClipLoader
                                size={15}
                                color={'#6D84E4'}
                                cssOverride={{marginRight: 5}}
                            />}
                                {mapValidationStateToTitle[validationState]}
                        </span>}
                    </div>
                    {emptyUserIdError && AccountValidationState.CORRECT_ID !== validationState &&
                        <label className={'App-input-invalid-label'}>Введите айди своего аккаунта</label>}
                </Section>
                <Section>
                    <Title>
                        Выберите тариф
                    </Title>
                    {premiums.map((item) => {
                        const {identifier, fancyName} = item;
                        const isChecked = identifier === activePremium;
                        return <SelectableBox
                            key={identifier}
                            identifier={identifier}
                            onClick={setActivePremium}
                            isChecked={isChecked}
                            title={fancyName}
                            subtitle={item.getFancyPrice()}
                        />;
                    })}
                </Section>
                <Section>
                    <Title>
                        Выберите метод оплаты
                    </Title>
                    {paymentMethods.map(({identifier, title, extra}) => {
                        const isChecked = identifier === method;
                        return <SelectableBox
                            key={identifier}
                            identifier={identifier}
                            onClick={setMethod}
                            isChecked={isChecked}
                            title={title}
                            subtitle={extra}
                        />;
                    })}
                    <Button
                        onClick={onFormSubmit}
                        title={'Перейти к оплате'}
                        isDisabled={AccountValidationState.CORRECT_ID !== validationState}
                        isFetching={isFetching}
                    />
                    {validationState === AccountValidationState.WRONG_ID && <span className={'App-wrong-user-id-error'}>
                Айди, который вы ввели, не существует. Попробуйте ввести его еще раз, чтобы мы знали куда начислить премиум.
            </span>}
                </Section>
            </form>
        </div>
    );
}

const Section = ({children}) => <div className={'App-Section'}>
    {children}
</div>

const Title = ({children}) => <p className={'App-title'}>
    {children}
</p>;

const Subtitle = ({children}) => <p className={'App-subtitle'}>
    {children}
</p>

const RadioButton = ({isChecked}) => <div className={'App-radio-button-outside'}>
    <div className={`${isChecked && 'App-radio-button-active'}`}/>
</div>;

const SelectableBox = ({identifier, isChecked, onClick, title, subtitle}) => <div
    key={identifier}
    className={`App-selectable-box ${isChecked && 'active'}`}
    onClick={() => onClick(identifier)}
>
    <RadioButton isChecked={isChecked}/>
    <span className={'App-selectable-box-title'}>{title}</span>
    <Spacer/>
    <span className={'App-selectable-box-title'}>{subtitle}</span>
</div>;

const Spacer = () => <div className={'spacer'}/>;


const useValidationState = (userId) => {
    const [accountValidationState, setAccountValidationState] = useState(AccountValidationState.NONE);
    useEffect(() => {
        if (userId.length === USER_ID_LENGTH) {
            setAccountValidationState(AccountValidationState.VALIDATING);
            validateAccount(userId)
                .then((res) => {
                    if (res) {
                        setAccountValidationState(AccountValidationState.CORRECT_ID);
                    } else {
                        setAccountValidationState(AccountValidationState.WRONG_ID);
                    }
                })
                .catch(() => setAccountValidationState(AccountValidationState.WRONG_ID));
        }
    }, [userId]);

    return [accountValidationState, setAccountValidationState];
}


export default App;
